<template>
  <section class="container">
    <h2 class="title">{{$t('GasPrice')}}</h2>
    <h3>{{$t('BasedPending')}}</h3>
    <ul class="flex-between">
      <li
        v-for="(item, i) in speed"
        :key="i"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/speed/' + (i + 1) + '.png') + ')',
        }"
      >
        <h4>{{ item.title }}</h4>
        <h1>{{ item.speed }}</h1>
        <p class="small-text">
          {{ item.speed ? "$" + computeGas(item.speed) : "--" }} |
          {{ item.time }}
        </p>
      </li>
    </ul>
    <h3>{{$t('EstimatedCost')}}</h3>
    <div class="table-responsive">
      <table v-if="list && list.length > 0">
        <thead>
          <tr>
            <th>{{$t('Name')}}</th>
            <th>{{$t('Label')}}</th>
            <th>{{$t('Interactions')}}</th>
            <th>{{$t('GasUsed')}}</th>
            <th>{{$t('Fast')}}</th>
            <th>{{$t('Standard')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in list" :key="i">
            <td>{{ item.name }}</td>
            <td>{{ item.label }}</td>
            <td class="min-width">{{ item.interactions }}</td>
            <td class="min-width">
              {{ webUtil.addCommas(item.gasUsed, 0) }}
            </td>
            <td>{{ fast ? "$" + computeGas(fast, item.gasUsed) : "--" }}</td>
            <td>
              {{ standard ? "$" + computeGas(standard, item.gasUsed) : "--" }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="null" v-else>{{$t('NoInformation')}}</div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      BNBprice: 0,
      timeInterval: null,
      speed: [
        {
          ID: 1,
          title: this.$t('Rapid'),
          time: "5-10 "+this.$t('Seconds'),
        },
        {
          ID: 2,
          title: this.$t('Fast'),
          time: "10-30 "+this.$t('Seconds'),
        },
        {
          ID: 3,
          title: this.$t('Standard'),
          time: "30-60 "+this.$t('Seconds'),
        },
        // {
        //   ID: 4,
        //   title: this.$t('Slow'),
        //   time: ">30 "+this.$t('Seconds'),
        // },
      ],
      list: [
        {
          name: "BNB",
          label: "",
          interactions: "Transfer",
          gasUsed: "21000",
        },
        {
          name: "USDT",
          label: "BEP20",
          interactions: "Transfer",
          gasUsed: "36115",
        },
        {
          name: "BUSD",
          label: "BEP20",
          interactions: "Transfer",
          gasUsed: "51127",
        },
        {
          name: "Pancake",
          label: "DEX",
          interactions: "Swap",
          gasUsed: "105657",
        },
        {
          name: "Pancake",
          label: "DEX",
          interactions: "Add Liquidity",
          gasUsed: "131820",
        },
        {
          name: "NFT",
          label: "BEP721",
          interactions: "Transfer",
          gasUsed: "127381",
        },
        {
          name: "NFT",
          label: "BEP721",
          interactions: "Mint",
          gasUsed: "272162",
        },
      ],
    };
  },
  created() {
    this.getGas();
    this.timeInterval = setInterval(() => {
      this.getGas();
    }, 5000);
  },
  beforeRouteLeave(to, from, next) {
    window.clearInterval(this.timeInterval);
    next();
  },
  computed: {
    fast() {
      let info = this.speed.filter((v) => v.ID == 2)[0];
      return info && info.speed;
    },
    standard() {
      let info = this.speed.filter((v) => v.ID == 3)[0];
      return info && info.speed;
    },
  },
  methods: {
    computeGas(num = 0, gasUsed = 21000) {
      return this.webUtil
        .BN(gasUsed)
        .times(num)
        .div(Math.pow(10, 9))
        .times(this.BNBprice)
        .toFixed(2, 1);
    },
    // getBNBprice() {
    //   this.axios
    //     .get(
    //       "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin"
    //     )
    //     .then((res) => {
    //       if (res && res.data && res.data[0]) {
    //         this.BNBprice = res.data[0].current_price;
    //       }
    //     });
    // },
    getGas() {
      this.axios.get('https://gbsc.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle').then(res=>{
        this.$loading(0);
        if(res.data.status==1){
          let data = res.data.result;
          this.BNBprice = data.UsdPrice;
          let speeds = [data.FastGasPrice,data.ProposeGasPrice,data.SafeGasPrice]

          speeds&&speeds.length>0&&this.speed.map((v, i) => {
            this.$set(v, "speed", this.webUtil.BN(speeds[i]).toFixed(4,1)-0);
          });
        }
      }).catch(err=>{
        console.log(err);
      })
    },
  },
};
</script>
<style scoped>
h3 {
  font: 700 20px/1.4 Rubik-Medium;
  padding: 48px 0 16px;
}
.flex-between {
  flex-wrap: wrap;
  margin-top: 8px;
}
.flex-between li {
  border: var(--border);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  padding: 16px 16px 24px 40px;
  border-radius: 4px;
  background-position: 12px 18px;
  background-size: 20px;
  background-repeat: no-repeat;
  margin-right: 16px;
  flex: 1;
}
.flex-between li:last-child {
  margin: 0;
}
h4 {
  font: normal 16px/1.5 Rubik-Regular;
}
h1 {
  font: 700 24px/32px Rubik-Medium;
  margin: 12px 0;
}
.small-text {
  font-size: 12px;
  color: var(--greyColor);
}
table .min-width {
  min-width: 140px;
}
/* @media (max-width: 1080px) {
  .flex-between li {
    width: 48%;
    flex: none;
  }
  .flex-between li:first-child {
    margin-bottom: 16px;
  }
  .flex-between li:nth-child(2) {
    margin: 0 0 16px;
  }
} */
@media (max-width: 768px) {
  h3 {
    padding: 24px 0 8px;
    font-size: 16px;
  }
  .flex-between li {
    width: 48%;
    flex: none;
  }
  .flex-between li:first-child {
    margin-bottom: 16px;
  }
  .flex-between li:nth-child(2) {
    margin: 0 0 16px;
  }
}
@media (max-width: 450px) {
  .flex-between li {
    width: 100%;
    margin: 0 0 16px;
  }
}
</style>